import React from 'react';

// material-ui
import { CircularProgress, circularProgressClasses } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import logo from '../assets/images/logo.svg';

// styles
const LoaderWrapper = styled('div')({
  position: 'fixed',
  zIndex: 1301,
  bottom: 40,
  right: 5,
  width: '100%',
  alignContent: 'center',
});
// ==============================|| LOADER ||============================== //
export default function Loader() {
  const theme = useTheme();
  return (
    <LoaderWrapper data-cy="loader">
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexwrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
            position: 'absolute',
          }}
          size={100}
          thickness={4}
          value={100}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: theme.palette.secondary[800],
            animationDuration: '1000ms',
            position: 'absolute',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'square',
            },
          }}
          size={100}
          thickness={2}
        />{' '}
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: theme.palette.secondary.dark,
            animationDuration: '1000ms',
            position: 'absolute',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'square',
            },
          }}
          size={100}
          thickness={1}
        />
        <Box sx={{ position: 'relative' }}>
          <img src={logo} alt="GOPASS" width="80" />
        </Box>
      </Box>
    </LoaderWrapper>
  );
}
