import React, { lazy } from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Loadable from './components/Loadable';

function App() {
  const ReconstructPdf = Loadable(
    lazy(() => import('./views/ReconstructionPdf')),
  );
  const Enroll = Loadable(lazy(() => import('./views/Enroll')));

  const router = createBrowserRouter([
    {
      path: '/reconstruction',
      element: <ReconstructPdf />,
    },
    {
      path: '/',
      element: <Enroll />,
    },
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
