import React, { Suspense } from 'react';

// project imports
import Loader from './Loader';

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //

const Loadable = (Component) =>
  function Internal(props) {
    const comp = (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
    return comp;
  };

export default Loadable;
